import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CallToAction } from '../../components/CalltoAction'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'

import { Alert } from '@material-ui/lab'
import { Totals } from '../../components/Cart'
import { ListRadio } from '../../components/Inputs'

import PaymentIcon from '@material-ui/icons/Payment'
import FormAsaasCreditCard from '../../components/Forms/FormAsaasCreditCard'
import CartContext from '../../contexts/Cart'
import PagSeguroContext from '../../contexts/PagSeguro'
import ShopContext from '../../contexts/Shop'
import UserContext from '../../contexts/User'
import GA4 from '../../services/GA4/ga4'

import MetaTags from '../../utils/MetaTags'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '55vh',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  containerForm: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRadius: 14,
  },
  payIcon: {
    marginTop: '-3px',
    color: theme.palette.text.secondary,
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    marginBottom: theme.spacing(3),
  },
  BtnContinue: {
    marginTop: theme.spacing(3),
  },
  mainTitle: {
    marginBottom: 16,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))

const CheckoutPayment = ({ ...rest }) => {
  const classes = useStyles(rest)
  const cartData = useContext(CartContext)
  const pagSeguro = useContext(PagSeguroContext)
  const shop = useContext(ShopContext)
  const userData = useContext(UserContext)

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [progress, setProgress] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [options, setOptions] = useState([])
  const [payment, setPayment] = useState(null)
  const [finish, setFinish] = useState(false)
  const [invoiceValue, setInvoiceValue] = useState(parseFloat(cartData.total) + parseFloat(cartData.shipping.cost || 0))
  const [errors, setErrors] = useState({
    number: null,
    validate: null,
    cvv: null,
    holderName: null,
    holderCPF: null,
    installment: null,
    holderName: null,
    holderCPF: null,
    message: 'Estamos com alguma instabilidade. Tente novamente em instantes. Padrão',
  })

  const history = useHistory()

  useEffect(() => {
    async function getInstallments() {
      if (pagSeguro.session_id) {
        pagSeguro
          .getInstallments(parseFloat(cartData.total) + parseFloat(cartData.shipping.cost || 0))
          .catch((error) => {
            console.log(error)
          })
      }
    }
    getInstallments()
    /* //pagSeguro.subscribe(getInstallments);
        cartData.subscribe(getInstallments);
    
        return function cleanup() {
            //pagSeguro.unsubscribe(getInstallments);
            cartData.unsubscribe(getInstallments);
        } */
  }, [])

  useEffect(() => {
    function updateState(data) {
      if (data.itens.length === 0) history.push('/carrinho')

      if (!data.person) history.push('/checkout/dados-pessoais')

      if (!data.address) history.push('/checkout/endereco')

      setInvoiceValue(parseFloat(data.total) + parseFloat(data.shipping.cost || 0))
      unblockPay()
    }

    cartData.subscribe(updateState)

    return function cleanup() {
      cartData.unsubscribe(updateState)
    }
  }, [cartData])

  useEffect(() => {
    unblockPay()

    pagSeguro.subscribe(unblockPay)

    return function cleanup() {
      pagSeguro.unsubscribe(unblockPay)
    }
  }, [pagSeguro])

  useEffect(() => {
    async function pagSeguroSession() {
      if (!pagSeguro.session_id) {
        setProgress(true)
        await pagSeguro.createSession()
        await pagSeguro.sendSession()
        await pagSeguro.senderHashReady()
        setProgress(false)
      }
    }
    pagSeguroSession()
  }, [])

  useEffect(() => {
    async function setPayments() {
      setProgress(true);
      const paymentsMethods = await cartData.fetchPaymentsMethods(shop.id);
      const tmpOptions = []
      paymentsMethods.forEach((payment) => {
        tmpOptions.push({
          label: payment.display_name || payment.formapagamento,
          value: payment.id,
          icon: payment?.forma_pagamento_integrador === 'CREDIT_CARD' ? 'cc' : 'boleto',
          data: payment,
        })
      })

      setOptions(tmpOptions);
      setProgress(false);
    }

    setPayments()
  }, [])

  function unblockPay() {
    setFinish(false)
    if (cartData.payment) {
      if (cartData.payment.code.toLowerCase() === 'pagseg_cc') {
        if (pagSeguro.creditCard) {
          if (
            pagSeguro.creditCard.number &&
            pagSeguro.creditCard.validate &&
            pagSeguro.creditCard.cvv &&
            pagSeguro.creditCard.holderName &&
            pagSeguro.creditCard.installment &&
            pagSeguro.creditCard.holderCPF
          ) {
            setFinish(true)
          } else setFinish(false)
        }
      } else {
        setFinish(true)
      }
    }
  }

  const handleCloseSnack = () => {
    setOpenSnackBar(false)
  }

  const handlePaymentsOptions = (payment) => {
    const tmpPay = cartData.paymentsMethods.filter((option) => {
      return option.id == payment
    })
    cartData.setPayment(tmpPay[0] || null)
    cartData.setTotal(tmpPay?.[0]?.['total_order']?.[0] || cartData.total)
    cartData.setTotalInstallment(null)
    setPayment(tmpPay[0])
    unblockPay()
  }

  const cleanErrors = () => {
    setErrors({
      number: null,
      validate: null,
      cvv: null,
      titular: null,
      installments: null,
      holderName: null,
      holderCPF: null,
      message: 'Estamos com alguma instabilidade. Tente novamente em instantes.',
    })
  }

  async function sendOrder() {
    /* if(!pagSeguro.senderHash)
            setTimeout(() => sendOrder, 2500); */
    setProgress(true)
    cleanErrors()

    try {
      await cartData.createOrder(shop.id, userData)
      await userData.setUser()
      setProgress(false)
      GA4.purchase(cartData.itens, cartData.lastInvoice)
      history.push('/checkout/confirmacao')
    } catch (err) {
      setProgress(false)
      if (err) {
        if (err?.response?.data?.errors) {
          setErrors(err.response.data.errors)
          return
        } else if (err.response.data.error) {
          if (err.response.data.error.code == '53142' || err.response.data.error.code == '53037') {
            setErrors({ ...errors, message: 'Verifique os dados do seu cartão de crédito.' })
            setOpenSnackBar(true)
            return
          } else if (err.response.data.error.code == '53044') {
            setErrors({ ...errors, holderName: 'Nome do títular deve estar igual ao nome impresso no cartão.' })
            return
          }
        }
      }
      setOpenSnackBar(true)
    }
  }

  return (
    <Fragment>
      <MetaTags
        meta_title={`Pagamento`}
        canonical_url={`https://${shop?.link}/checkout/pagamento`}
        //meta_description="Brindes Personalizados originais e exclusivos! Empresa de Brindes há 22 anos atuando com Brindes Corporativos e Brindes para Empresas com orçamento online. Brindes RJ e demais regiões."
        nome_loja={shop?.nome || 'Sablier'}
        image={shop?.logo}
      />
      <Header />
      <Container className={classes.root}>
        <Typography variant="h4" component="h2" className={classes.mainTitle}>
          4. Selecione a forma de pagamento
        </Typography>
        <Grid spacing={3} container>
          <Grid container item xs={12} sm={12} md={12} lg={9} style={{ marginLeft: mobile ? 12 : 0 }}>
            {!options.length && <CircularProgress className={classes.progress} /> }
            {options.length > 0 && <ListRadio itens={options} changeValue={handlePaymentsOptions} /> }
            {payment &&
              payment.integrador?.toLowerCase() === 'asaas' &&
              payment.forma_pagamento_integrador?.toLowerCase() === 'credit_card' && (
                <Grid item xs={12} style={{ marginLeft: mobile ? '-8px' : '-12px' }}>
                  <Paper className={classes.containerForm} elevation={0} style={{ width: 'calc(100% + 7px)' }}>
                    <Typography variant="h5" component="h4" className={classes.title}>
                      <PaymentIcon fontSize="large" className={classes.payIcon} /> Dados do Cartão de crédito
                    </Typography>
                    <FormAsaasCreditCard
                      onChangeValues={(_, isValid) => {
                        setFinish(isValid)
                      }}
                      totalOrder={payment?.total_order}
                      invoiceValue={invoiceValue}
                      //getData={handleFormPagSeguro}
                      errors={errors}
                    />
                  </Paper>
                </Grid>
              )}
          </Grid>
          <Grid container direction="column" item xs={12} sm={12} md={12} lg={3}>
            <Totals zipCode={cartData.shipping.zip_code} disableZipCode />
            <div className={classes.BtnContinue}>
              <CallToAction text="Finalizar Compra" scheme="primary" onClick={sendOrder} disabled={!finish} fullWidth />
            </div>
            <div className={classes.BtnContinue}>
              <CallToAction text="Voltar" scheme="primary" href="/checkout/endereco" fullWidth />
            </div>
          </Grid>
        </Grid>
      </Container>
      <Footer />
      <Snackbar
        open={openSnackBar}
        onClose={handleCloseSnack}
        autoHideDuration={7000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={handleCloseSnack}>
          {errors.message}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={progress}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  )
}

export default CheckoutPayment
